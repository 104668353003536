
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class extends Vue {
  @Watch("$route")
  private changeRoute() {
    this.get_scroll();
  }

  public drawer = false;
  public scrollY = 0;
  public color_change_set = false;
  public color_none_set = false;
  public nominal_set = false;

  private mounted() {
    document.addEventListener("DOMContentLoaded", this.get_scroll);
    window.addEventListener("scroll", this.get_scroll);
  }
  private get_scroll() {
    if (this.$route.path === "/") {
      this.nominal_set = false;
      this.scrollY = window.scrollY;
      if (this.scrollY > 0) {
        this.color_change_set = true;
      } else if (this.scrollY === 0) {
        this.color_none_set = true;
        this.color_change_set = false;
      }
    } else {
      this.nominal_set = true;
    }
  }
}
