
import { Component, Vue } from "vue-property-decorator";
import { ToggleButton } from "vue-js-toggle-button";

Vue.component("ToggleButton", ToggleButton);

@Component({
  components: {},
})
export default class extends Vue {
  public area = 0;
  public adjacent_land = 1;
  public total = 0;
  public fix_road_boundary_line = true;
  public area_re_registration = true;

  // 表示用リスト(固定のpriceはデフォルトセット)
  public display_price_list = [
    { label: "資料調査・事前測量", price: 35000 },
    { label: "多角測量", price: 36000 },
    { label: "画地調整", price: 20000 },
    { label: "民民立会い", price: 0 },
    { label: "官民確定申請・境界埋設", price: 0 },
    { label: "面積測量", price: 0 },
    { label: "境界点測設（仮杭）", price: 30000 },
    { label: "境界点測設（本杭）", price: 45000 },
    { label: "地積更正", price: 0 },
    { label: "調査報告書", price: 0 },
    { label: "筆界確認書", price: 0 },
    { label: "道路境界証明書", price: 10000 },
    { label: "コンクリート杭等", price: 3000 },
  ];

  // 計算用単価リスト
  public unit_price_list = [
    { label: "民民立会い", price: 15000 },
    { label: "官民確定申請・境界埋設", price: 150000 },
    { label: "地積更正", price: 80000 },
    { label: "調査報告書", price: 10000 },
    { label: "筆界確認書", price: 30000 },
  ];

  private created() {
    this.calc_attendance_demarcation();
    this.fix_road_boundary_change();
    this.area_re_registration_change();
    this.calc_area();
    this.calc_total();
  }

  private updated() {
    this.calc_total();
  }

  // 隣接している土地をプラス
  private add() {
    this.adjacent_land = this.adjacent_land + 1;
    this.calc_attendance_demarcation();
  }

  // 隣接している土地をマイナス
  private sub() {
    if (this.adjacent_land <= 1) return;
    this.adjacent_land = this.adjacent_land - 1;
    this.calc_attendance_demarcation();
  }

  // 民民立ち合いと筆界確認書費用の計算
  private calc_attendance_demarcation() {
    this.display_price_list[3].price =
      this.adjacent_land * this.unit_price_list[0].price;
    this.display_price_list[10].price =
      this.adjacent_land * this.unit_price_list[4].price;
  }

  // 道路境界確定有無スイッチ
  private fix_road_boundary_change() {
    this.fix_road_boundary_line
      ? (this.display_price_list[4].price = 0)
      : (this.display_price_list[4].price = this.unit_price_list[1].price);
  }

  // 地積更正登記有無スイッチ
  private area_re_registration_change() {
    this.area_re_registration
      ? ((this.display_price_list[8].price = this.unit_price_list[2].price),
        (this.display_price_list[9].price = this.unit_price_list[3].price))
      : ((this.display_price_list[8].price = 0),
        (this.display_price_list[9].price = 0));
  }

  // 小計計算
  private calc_total() {
    this.total = this.display_price_list.reduce(function (
      previousValue,
      currentValue
    ) {
      return previousValue + currentValue.price;
    },
    0);
  }

  // カンマ区切り
  private price_format(val: string) {
    return val ? val.toLocaleString() : 0;
  }

  // 土地面積あたりの面積測量費計算
  private calc_area() {
    let result = 0;
    switch (true) {
      case 5000 < this.area:
        result = 206890 + Math.floor((this.area - 5000) / 1000) * 15820;
        break;
      case 4000 < this.area && this.area <= 5000:
        result = 206890;
        break;
      case 3000 < this.area && this.area <= 4000:
        result = 186690;
        break;
      case 2000 < this.area && this.area <= 3000:
        result = 163780;
        break;
      case 1000 < this.area && this.area <= 2000:
        result = 136600;
        break;
      case 800 < this.area && this.area <= 1000:
        result = 101160;
        break;
      case 600 < this.area && this.area <= 800:
        result = 92130;
        break;
      case 400 < this.area && this.area <= 600:
        result = 81940;
        break;
      case 300 < this.area && this.area <= 400:
        result = 69700;
        break;
      case 200 < this.area && this.area <= 300:
        result = 62520;
        break;
      case 100 < this.area && this.area <= 200:
        result = 53880;
        break;
      case this.area <= 100:
        result = 42710;
        break;
    }
    this.display_price_list[5].price = result;
  }
}
