
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class extends Vue {
  public services = [
    {
      id: 1,
      title: "境界、面積を知りたい時",
      text: "土地の境界線を確定したい時は、隣地と境界立会いを行います。",
    },
    {
      id: 2,
      title: "土地を分けたい時",
      text: "一筆の土地を二筆以上の土地に分ける分筆登記を行います。",
    },
    {
      id: 3,
      title: "宅地などに変更した時",
      text: "土地の使用目的に変更があった場合に地目変更登記を行います。",
    },
    {
      id: 4,
      title: "建て替えをした時",
      text: "建物を取壊した時は、滅失登記を行い、新築した時は、表題登記を行います。",
    },
    {
      id: 5,
      title: "増築した時",
      text: "建物を増築した時は、表題部変更登記を行います。",
    },
  ];
}
