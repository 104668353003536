import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VuePrlx from "vue-prlx";

Vue.use(VuePrlx);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  methods: {
    createPageTitle: function (to: any) {
      // タイトルを設定
      if (to.meta.title) {
        document.title = to.meta.title + " | 細野測量";
      } else {
        document.title = "細野測量";
      }

      document
        .querySelector("link[rel='canonical']")!
        .setAttribute("href", "http://www.hosono-s.jp" + to.path);

      // メタタグdescription設定
      if (to.meta.desc) {
        document
          .querySelector("meta[name='description']")!
          .setAttribute("content", to.meta.desc);
      }
    },
  },
  watch: {
    //ページ移動するたびにタイトルを整形していく
    $route(to, _from) {
      this.createPageTitle(to);
    },
  },
}).$mount("#app");
