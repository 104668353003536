
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class extends Vue {
  public surveys = [
    {
      id: 1,
      title: "境界、面積を知りたい時",
      text: "隣接所有者の方々と境界立会いを行い境界を確定し、面積を算出します。（確定測量）",
    },
    {
      id: 2,
      title: "現況面積や高低差が知りたい時",
      text: "土地や家屋の面積・形状・高低差などを精密に測定します。土地の現況や、敷地面積を出したり、土地の実際の形状を図面化します。",
    },
    {
      id: 3,
      title: "境界点を復元したい時",
      text: "法務局備え付けの図面等をもとに、境界点を現地に復元します。復元した境界点に、境界標識を設置することも可能です。",
    },
    {
      id: 4,
      title: "真北が知りたい時",
      text: "建築確認を申請する際に真北が必要な時は、太陽による真北測量を行います。",
    },
  ];
  public registrations = [
    {
      id: 1,
      title: "土地を分けたい時",
      text: "一筆の土地を二筆以上の土地に分ける分筆登記を行います。",
    },
    {
      id: 2,
      title: "宅地などに変更した時",
      text: "土地の利用目的に変更があった場合に地目変更登記を行います。",
    },
    {
      id: 3,
      title: "建て替えをした時",
      text: "建物を取壊した時は、滅失登記を行い、新築した時は、表題登記を行います。",
    },
    {
      id: 4,
      title: "増築した時",
      text: "建物を増築した時は、表題部変更登記を行います。",
    },
  ];
}
