import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Index from "../views/Index.vue";
import About from "../views/About.vue";
import Service from "../views/Service.vue";
import Works from "../views/Works.vue";
import Quote from "../views/Quote.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Index",
    meta: {
      title: "",
      description:
        "おかげさまで40年。相模原にある細野測量は、土地・建物の測量・登記をする専門家です。皆様の大切な不動産を正確かつ迅速に調査・測量をして法務局へ登記します。",
    },
    component: Index,
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: "会社案内",
      description:
        "弊社の知識と技術の向上を図り持続可能な会社になるように努めていきます。何かありましたらお気軽にご相談ください。",
    },
    component: About,
  },
  {
    path: "/service",
    name: "Service",
    meta: {
      title: "事業案内",
      description:
        "不動産は次代へと受け継がれる貴重な財産です。また、土地の売却や相続の際には、調査・測量が必要になるケースが多いです。",
    },
    component: Service,
  },
  {
    path: "/works",
    name: "Works",
    meta: {
      title: "実績紹介",
      description:
        "公共測量、開発許可申請等、弊社のこれまでの代表的な実績です。",
    },
    component: Works,
  },
  {
    path: "/quote",
    name: "Quote",
    meta: {
      title: "お見積り",
      description:
        "簡易お見積りをすることができます。目安としてご利用ください。",
    },
    component: Quote,
  },
  {
    path: "*",
    name: "NotFound",
    meta: {
      title: "ページが見つかりません",
      description: "ページが見つかりませんでした。",
    },
    component: () => import("../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(_to, _from, _savedPosition) {
    // always scroll to top
    return { x: 0, y: 0 };
  },
});

export default router;
