
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class extends Vue {
  public overviews = [
    { id: 1, title: "名称", text: "株式会社細野測量" },
    {
      id: 2,
      title: "所在地",
      text: "〒252-0239 神奈川県相模原市中央区中央2丁目3番11号",
    },
    { id: 3, title: "設立", text: "昭和57年1月9日" },
    { id: 4, title: "電話", text: "042-755-5533" },
    { id: 5, title: "メール", text: "survey@hosono-s.jp" },
    { id: 6, title: "代表取締役", text: "細野　雄太郎" },
    { id: 7, title: "専務取締役", text: "細野　健太" },
    { id: 8, title: "会長", text: "細野　正明" },
    { id: 9, title: "資格", text: "測量士３名、土地家屋調査士２名" },
  ];
}
